<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <b-row>
      <b-col md="9">
        <h2>Global E-Mail Template</h2>
        <div class="white-bg-container">
          <b-row>
            <b-col>
              <b-form-group
                label="Global E-Mail Body Template:"
                label-for="emailBodySection2"
              >
                <vue-editor
                  id="editor"
                  v-model="Body"
                  class="font-weight-normal"
                  :editor-toolbar="customToolbar"
                  :disabled="readOnly"
                >
                </vue-editor>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col class="pt-3">
        <div class="white-bg-container">
          <b-row class="pb-1">
            <b-col>
              Template Tokens
            </b-col>
          </b-row>
          <b-row
            v-for="tempToken in TemplateTokens"
            :key="tempToken"
          >
            <b-col>
              {{ tempToken }}
            </b-col>
          </b-row>

        </div>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveTemplate()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import {VueEditor} from "vue2-editor";

const apiService = new APIService();

export default {
  components: {
    BBreadcrumb,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Body: "",
      readOnly: false,
      template: "",
      templateID: "",
      TemplateTokens: [],
      customToolbar: [[{
        header: [false, 1, 2, 3, 4, 5, 6]
      }], ["bold", "italic", "underline", "strike"], // toggled buttons
        [{
          align: ""
        }, {
          align: "center"
        }, {
          align: "right"
        }, {
          align: "justify"
        }], ["blockquote", "code-block"], [{
          list: "ordered"
        }, {
          list: "bullet"
        }], [{
          indent: "-1"
        }, {
          indent: "+1"
        }],
        [{
          color: []
        }, {
          background: []
        }],["link"]
      ],
    }
  },
  computed: {
    breadcrumbItems: function() {
      return [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Templates',
          href: '/admin/templates'
        },
        {
          text: "Global E-Mail Template",
        },
      ]
    },
  },
  watch: {},
  async created() {
    this.template = this.$route.meta.templateId;
    this.templateID = this.$route.meta.templateId;
    try {
      this.getTemplate(this.templateID);
      this.getTemplateTokens(this.templateID);
      this.getUserInfo();
    } catch (err) {
      this.error = err;
    }
  },
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    getUserInfo() {
      apiService
          .get("auth/userinfo")
          .then(res => {
            this.userObj = res.data;
          })
    },
    getTemplate(templateID) {
      apiService
          .get("template/" + templateID)
          .then(res => {
            this.Body = res.data.templateData.Body;
          })
    },
    getTemplateTokens(templateID) {
      apiService
          .get("template/" + templateID + "/tokens")
          .then(res => {
            this.TemplateTokens = res.data;
          })
    },
    isEmpty(val) {
      return val ? val : "";
    },
    resetForm() {
      this.$router.push('/admin/templates');
    },

    objToArr(obj) {
      return Object.keys(obj).map((key) => obj[key]).join();
    },

    saveTemplate() {
      const encoder = new TextEncoder();
      const postData = {
        TemplateData: {
          "Body": this.objToArr(encoder.encode(this.Body)),
        }

      }
      apiService.post("template/" + this.templateID, postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Template updated!');
          this.$router.push('/admin/templates');
        }
      })
    },
  }
}
</script>

<style scoped>

</style>